import React from 'react';
import Referral from '../components/updated/Referral';

const ReferralPage = () => {
  return (
    <div>
      <Referral />
    </div>
  );
};

export default ReferralPage;
